<template>
  <Loading v-if="isInitLoading" />
  <div class="purchaseDetail" v-else>
    <Header callBack @click="goBack" @clickRight="clickRight"
      >藏品详情
      <template v-slot:right
        ><img
          class="doubt"
          src="../../../static/image/doubt.png"
          alt="" /></template
    ></Header>
    <div class="container">
      <div class="banner">
        <img :src="info.show_pic_url" alt="" />
      </div>
      <div class="info">
        <div class="title">{{ info.name }}</div>
        <div class="time">
          申购时间：{{ info.applyBegin }}至{{ info.applyEnd }}
        </div>
        <!-- <div class="count" v-if="info.buttonState != 0">
          当前总申购份数：{{ info.userApplyNum || 0 }}份
        </div> -->
        <div class="tag">
          <div class="tagItem">
            <div class="left">发行量</div>
            <div class="right">{{ info.stockNum || 0 }}份</div>
          </div>
          <div class="tagItem">
            <div class="left">申购量</div>
            <div class="right">{{ info.applyNum || 0 }}份</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="notes" v-html="info.notes"></div>
      <div class="line"></div>
      <div class="possess">
        <div>
          <div class="possess_name">
            {{ info.craftsman.nickName }} <span>创作者/版权方</span>
          </div>
          <div class="possess_img">
            <span>{{ info.address }}</span>
            <img
              @click="copy(info.address)"
              src="../../../static/image/fuzhi@2x.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div v-html="info.describe" class="describe"></div>
    </div>
    <div class="bottom">
      <div class="left">
        ¥<span>{{ info.applyPrice || 0 }}</span>
      </div>
      <div class="right def" v-if="info.buttonState == 0">未到申购时间</div>
      <div class="right" @click="goDetail" v-else-if="info.buttonState == 1">
        查看我的申购
      </div>
      <div class="right def" v-else-if="info.buttonState == 2">申购已结束</div>
      <div class="right" @click="toggleShowBox" v-else>立即申购</div>
    </div>
    <van-popup
      v-model:show="showBox"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div class="pop">
        <div class="header">
          <div></div>
          <div class="middle">选择申购份数</div>
          <div class="right">
            <i @click="showBox = false"></i>
          </div>
        </div>
        <div class="middle">
          <div class="num">
            <div
              class="left"
              @click="num <= 1 ? num : num--"
              :class="num <= 1 ? 'desable' : null"
            >
              -
            </div>
            <div class="input">
              <input type="text" @input="specifiName($event)" v-model="num" />
            </div>
            <div
              class="right"
              @click="num >= info.applyNum ? num : num++"
              :class="num >= info.applyNum ? 'desable' : null"
            >
              +
            </div>
          </div>
          <div class="info">（上限{{ info.applyNum || 0 }}份）</div>
          <div class="total">
            总金额：
            <span>￥{{ totalPic }}</span>
          </div>
        </div>
        <div class="btn" @click="goTo">去支付</div>
      </div>
    </van-popup>
    <div
      v-if="isToHome && !$route.query.platform"
      class="openInApp"
      @click="openApp"
    >
      <div class="img">
        <img src="../../../static/image/logo1.png" alt="" />
      </div>
      <span>APP内打开</span>
    </div>
    <van-popup v-model:show="showPopUp" class="popUp">
      <div class="header">申购规则</div>
      <div class="content" v-html="applyrule"></div>
      <div class="bottom1" @click="showPopUp = false">我知道了</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isInitLoading: true,
      showPopUp: false,
      showBox: false,
      info: { craftsman: {} },
      num: 1,
      timer: null,
      isToHome: false,
    };
  },
  computed: {
    totalPic() {
      return parseFloat(this.num * this.info.applyPrice).toFixed(2);
    },
    applyrule() {
      return this.$store.state.anyInfo.applyrule;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/") {
        vm.isToHome = true;
      } else {
        vm.isToHome = false;
      }
    });
  },
  beforeDestroy() {
    this.clearTimer();
  },
  mounted() {
    this.clearTimer();
    this.getDetail();
    if (!this.applyrule) {
      this.$api.anyInfo().then((res) => {
        if (res.code == 0) {
          this.$store.commit("SET_ANYINFO", res.data);
        }
      });
    }
  },
  methods: {
    clickRight() {
      this.showPopUp = true;
    },
    goBack() {
      if (this.isToHome) {
        this.$router.replace("/home");
      } else {
        this.$router.back();
      }
    },
    openApp() {
      window.open("https://v.hxnft.shop/appDownload/index.html");
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    timeOut(key) {
      this.timer = setInterval(() => {
        if (this.info[key] && this.info[key] > 0) {
          this.info[key]--;
        } else {
          this.clearTimer();
          this.getDetail();
        }
      }, 1000);
    },
    getDetail() {
      this.$api
        .applyGoodsInfo({
          id: this.$route.query.id,
          token: localStorage.getItem("token"),
        })
        .then(
          (e) => {
            this.isInitLoading = false;
            if (e.code === 0) {
              this.info = e.data;
              if (this.info.runBeginTime > 0) {
                // 倒计时
                this.timeOut("runBeginTime");
              } else if (this.info.runEndTime > 0) {
                // 倒计时
                this.timeOut("runEndTime");
              }
            } else {
              return this.$toast(e.msg);
            }
          },
          () => {
            this.isInitLoading = false;
          }
        );
    },
    async toggleShowBox() {
      if (!localStorage.getItem("token")) {
        if (this.$route.query.code) {
          return this.$router.push(
            `/login?code=${this.$route.query.code}&redirect=/purchaseDetail?id=${this.$route.query.id}`
          );
        }
        return this.$router.push("/login");
      }
      const res = await this.$api.userBehaviorState({
        token: localStorage.getItem("token"),
      });
      if (res.isBlacklist == 1) return this.$toast(res.msg);
      this.showBox = true;
    },
    goTo() {
      if (this.num <= 0) return this.$toast("请输入申购份数");
      const query = {
        token: localStorage.getItem("token"),
        id: this.info.id,
        applyNumber: this.num,
      };
      this.$api.userRunApply(query).then((e) => {
        if (e.code === 100000) {
          e.data.couponCount = this.info.couponCount;
          this.$router.push(
            `/purchasePay?info=${JSON.stringify(e.data || {})}`
          );
        } else {
          return this.$toast(e.msg);
        }
      });
    },
    // 实时监听
    specifiName(e) {
      let res = e.target.value.match(/^\d{0,8}/g)[0] || "";
      // if (res <= 1) res = 1;
      if (res > this.info.applyNum) res = this.info.applyNum;
      this.$nextTick(() => {
        this.num = res;
      });
    },
    goDetail() {
      if (!localStorage.getItem("token")) {
        return this.$router.push("/login");
      }
      this.$router.push(`/unpaid?id=${this.info.order_id}&orderType=3`);
    },
  },
};
</script>

<style scoped lang="less">
.notes {
  font-size: 14px;
  padding: 10px 20px;
}
.purchaseDetail {
  .doubt {
    width: 18px;
  }
  .container {
    padding-bottom: 60px;
    .banner {
      img {
        max-width: 100%;
        vertical-align: middle;
      }
    }
    .info {
      padding: 20px;
      .title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .time {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        margin: 8px 0;
      }
      .count {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-bottom: 13px;
      }
      .tag {
        display: flex;
        align-items: center;
        .tagItem {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          margin-right: 10px;
          color: #ffffff;
          .left {
            background: #abaab9;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px 8px;
          }
          .right {
            padding: 2px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #4c4b58;
            background: #f3f4f8;
          }
        }
      }
    }
    .line {
      height: 5px;
      background: #f8f8f8;
    }

    .possess {
      padding: 20px;
      img {
        display: block;
        width: 36px;
        height: 36px;
        padding-right: 15px;
      }
      .possess_name {
        font-size: 12px;
        font-weight: 600;
        span {
          color: #0754d3;
          font-size: 12px;
          font-style: italic;
        }
        .owner {
          color: #e87122;
        }
      }
      .possess_img {
        display: flex;
        align-items: center;
        font-size: 14px;
        &.between {
          .time {
            font-size: 12px;
            text-align: right;
            flex: 1;
          }
        }
        span {
          display: block;
          width: 130px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        img {
          display: block;
          width: 10px;
          height: 10px;
          margin-left: 10px;
        }
      }
      .open {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 10px;
        height: 10px;
        border-bottom: 2px solid #aaa;
        border-right: 2px solid #aaa;
        transition: all 0.3s;
      }
      .openImg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        padding: 0;
      }
    }
  }
  .bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #ffffff;
    justify-content: center;
    .left {
      flex: 1;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      span {
        font-size: 26px;
        margin-left: 3px;
      }
    }
    .right {
      width: 165px;
      height: 40px;
      background: #0754d3;
      border-radius: 5px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      &.def {
        background: #ebebeb;
        color: #999999;
      }
    }
  }
  .pop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 15px 0;
    box-sizing: border-box;
    .header {
      display: flex;
      align-items: center;
      div {
        flex: 1;
        &.middle {
          flex: 2;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #000000;
          text-align: center;
        }
        &.right {
          text-align: right;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          i {
            position: relative;
            display: block;
            width: 15px;
            height: 15px;
            margin-right: 10px;
            &::after {
              position: absolute;
              width: 100%;
              height: 2px;
              background: #434343;
              display: block;
              content: "";
              transform: translate(-50%, -50%) rotate(-45deg);
              left: 50%;
              top: 50%;
            }
            &::before {
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
              display: block;
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              background: #434343;
            }
          }
        }
      }
    }
    .middle {
      text-align: center;
    }
    .num {
      display: inline-flex;
      align-items: center;
      background: #f8f8f8;
      height: 40px;
      border-radius: 25px;
      .left {
        display: inline-flex;
        align-items: center;
        font-family: PingFang SC;
        font-weight: 400;
        color: #659fff;
        vertical-align: middle;
        padding: 0 20px;
      }
      .input {
        width: 130px;
        border-left: 1px solid #e5e5e5;
        border-right: 1px solid #e5e5e5;
        vertical-align: middle;
        input {
          border: none;
          outline: none;
          text-align: center;
          width: 100%;
          background: transparent;
          font-size: 16px;
          display: flex;
          align-items: center;
        }
      }
      .right {
        vertical-align: middle;
        padding: 0 20px;
        display: inline-flex;
        align-items: center;
        font-family: PingFang SC;
        font-weight: 400;
        color: #659fff;
      }
      .desable {
        color: #cecece;
      }
    }
    .info {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin: 12px 0 35px;
    }
    .total {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
      text-align: center;
      span {
        color: #e76810;
      }
    }
    .btn {
      width: 261px;
      height: 40px;
      background: #0754d3;
      border-radius: 5px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }
}
.openInApp {
  position: fixed;
  right: 0;
  bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 3px;
  .img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
  span {
    font-size: 12px;
  }
}
.popUp {
  width: 80%;
  border-radius: 4px;
  .header {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    padding: 20px 0 15px;
  }
  .content {
    margin: 15px 0;
    overflow: auto;
    margin: 0.4rem 0;
    height: 60vh;
    // min-height: 20vh;
    padding: 0 20px;
    font-size: 16px;
  }
  .bottom1 {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0754d3;
    text-align: center;
    padding: 15px;
    border-top: 1px solid #ececec;
  }
}
</style>

<style lang="less">
.purchaseDetail {
  .describe {
    padding: 20px;
    img {
      max-width: 100%;
    }
  }
}
</style>
